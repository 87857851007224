import { MacbookScroll } from './macbook-scroll';
import { ContainerScroll } from './container-scroll-animation';

export default function Hero() {
  return (
    <>
      <br />
      <div className="overflow-hidden w-full  ">
        <div className="flex flex-col overflow-hidden md:hidden">
          <ContainerScroll
            titleComponent={
              <>
                <h1 className="text-4xl font-semibold text-black dark:text-white">
                  আপনি কি প্রস্তুত? <br />
                  <span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent text-4xl md:text-[4rem] font-bold mt-1 leading-tight">
                    ব্যবসায়িক ক্ষতি কমিয়ে <br />
                    লাভবান হতে শুরু করুন
                  </span>
                </h1>
              </>
            }
          >
            <img
              src={`/dashboard.png`}
              alt="hero"
              height={720}
              width={1400}
              className="mx-auto rounded-2xl object-cover h-full object-left-top"
              draggable={false}
            />
          </ContainerScroll>
        </div>

        <div className="hidden md:block">
          <MacbookScroll
            title={
              <>
                <h1 className="text-4xl md:text-5xl font-semibold text-black dark:text-white leading-tight">
                  আপনি কি প্রস্তুত?
                </h1>
                <h2 className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent text-4xl md:text-5xl font-bold mt-2 md:mt-7">
                  ব্যবসায়িক ক্ষতি কমাতে <br />
                  লাভ করা শুরু করতে
                </h2>
              </>
            }
            badge={<></>}
            src={`/dashboard.png`}
            showGradient={false}
          />
        </div>
      </div>
    </>
  );
}
